<script lang="ts" setup>
import type { BlogListResponse } from "@/types/api";

const props = defineProps<{
  tag?: string | undefined;
}>();

const { locale } = useI18n();
const { data } = await useAsyncData<BlogListResponse>(locale.value + "_component_blogs_" + props.tag, () =>
  useNuxtApp().$siteFetch("/v1/content/blog", {
    params: {
      tag: props.tag,
      "page[size]": 8,
    },
  })
);
</script>

<template>
  <section class="py-8" v-if="data && data?.data.length > 0">
    <h4 class="mb-3 text-3xl font-extrabold leading-tight tracking-tight text-black md:text-4xl">
      {{ $t("components.blogs.title") }}
    </h4>
    <p class="mb-8 text-base font-normal text-gray md:text-xl">
      {{ $t("components.blogs.subtitle") }}
    </p>
    <v-row>
      <v-col cols="6" md="3" v-for="item in data?.data">
        <BlogItem :item="item" />
      </v-col>
    </v-row>
  </section>
</template>
